jQuery(document).ready(function($) {

  const triangle = document.getElementById('animating-triangle');

  if (window.innerWidth > 1199) {

    if (triangle != null) {

      const speed = 10;
      const animatingElements = document.querySelectorAll('.animating');
      animatingElements.forEach((aElement) => {
        aElement.style.animationDuration = speed.value + 's';
      });

      let wink = triangle.querySelector('.wink');
      let eye = triangle.querySelector('.smallcircle');

      setInterval(() => {
        wink.classList.add('active');
        eye.classList.remove('active'); 
        setTimeout(() => {
          wink.classList.remove('active');
          eye.classList.add('active');     
        }, 500);
      }, 5000);

      // animate when leaving screen
      let triangleShape = triangle.querySelector('.triangle');
      let circle = triangle.querySelector('.circle');
      let triangleContainer = triangle.closest('.row');
      let triangleContainerHeight = triangleContainer.offsetHeight;
      let windowHeight = window.innerHeight;
      let offsetValue = windowHeight * 0.18;

      window.addEventListener('scroll', () => {
        let windowScrollOffset = window.scrollY - offsetValue;
        let triangleScale = (1 - (windowScrollOffset * 0.005));
        let trianglePadding = (windowScrollOffset / 1.5);
        let opacityChange = (1 - (windowScrollOffset * 0.008));


        if (window.scrollY > offsetValue) {

          if (triangleScale > -0.00001) {
            triangleShape.style.setProperty('--triangle-scale', triangleScale);
            triangle.style.setProperty('--graphic-opacity', opacityChange);
            if (triangleScale < 0.3) {
              triangleShape.classList.add('opacity-0');
            } else {
              triangleShape.classList.remove('opacity-0');
            }
          }
        
        } else {
          // reset the triangle
          triangleShape.style.setProperty('--triangle-scale', 1);
          triangle.style.setProperty('--graphic-opacity', 1);
          triangleShape.classList.remove('opacity-0');
          triangle.classList.remove('hide');
          triangle.classList.remove('sticky');
        }

        if (window.scrollY > offsetValue && window.scrollY < triangleContainerHeight) {
          triangle.classList.add('sticky');

          if (window.scrollY > (triangleContainerHeight - offsetValue)) {
            triangle.classList.add('hide');
          } else {
            triangle.classList.remove('hide');
          }

        } else {

          triangle.classList.remove('sticky');
        }

      });

      // var observer = new IntersectionObserver((entries) => {
      //   animationObserverCallback(entries, observer, triangle);
      //   }, 
      //   { threshold: 0.25 });
      //   observer.observe(triangle);

      // let entryPosition;
      // let animationStartPoint = 2 * (window.innerHeight/3);

      // const animationObserverCallback = (entries, observer, element) => {
      //   entries.forEach((entry, animatingElement) => {
      //     if (entry.isIntersecting) {
      //       entryPosition = entry.target.getBoundingClientRect();
   
      //        if (entryPosition.top < animationStartPoint) {
      //         entry.target.classList.add('active');
      //       }
      //     }
      //   });

      // };

    }
  }
});